<template>
	<Dialog
		:dialog="dialog"
		title="Expense Details"
		current
		v-on:close="dialog = false"
		:dialogWidth="1120"
	>
		<template v-slot:title>
			<span>Expense Detail</span>
			<v-chip color="cyan" class="ml-2" label text-color="white">{{ expense_detail.barcode }}</v-chip>
			<!-- <v-chip
				:color="getStatusColor(expense_detail.status_value)"
				class="ml-2"
				label
				text-color="white"
				>{{ expense_detail.status_value }}</v-chip
			> -->
			<Chip
				:text="expense_detail.expense_type"
				tooltip
				class="ml-2"
				label
				tooltip-text="Expense Type"
				:color="expense_detail.expense_type === 'individual' ? 'orange' : 'green'"
			></Chip>
			<v-spacer></v-spacer>
			<v-btn fab depressed x-small v-on:click="$emit('close', false)">
				<v-icon>mdi-close</v-icon>
			</v-btn>
			<v-menu
				v-if="false"
				bottom
				left
				origin="center center"
				transition="slide-y-transition"
				rounded="0"
				offset-y
				max-height="400px"
				:close-on-content-click="true"
				content-class="white-background"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						:disabled="pageLoading"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon left>mdi-chevron-down</v-icon>
						Bulk Action
					</v-btn>
				</template>
				<v-list class="py-0">
					<template v-for="(item, i) in actions">
						<v-list-item link :key="i">
							<v-list-item-title
								><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
								>{{ item.title }}</v-list-item-title
							>
						</v-list-item>
						<v-divider v-if="actions[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
					</template>
				</v-list>
			</v-menu>
		</template>
		<template v-slot:body>
			<Loading v-if="pageLoading" />
			<v-tabs
				v-else
				v-model="expenseTab"
				background-color="transparent"
				color="blue"
				class="custom-tab-transparent"
				active-class="blue darken-4 text-white"
				hide-slider
			>
				<!-- {{files}} -->
				<v-tab href="#detail">
					<v-icon small left>mdi-format-list-bulleted-square</v-icon>
					Detail</v-tab
				>
				<v-tab href="#files">
					<v-icon small left>mdi-file-multiple</v-icon>
					Files</v-tab
				>
			</v-tabs>
			<v-tabs-items v-model="expenseTab">
				<v-tab-item value="detail">
					<div class="overflow-y" style="max-height: calc(100vh - 250px)">
						<v-row>
							<v-col md="6">
								<div>
									<span class="fw-600 title-heading">Title : </span>
									<ShowValue :object="expense_detail" object-key="title" label="title"> </ShowValue>
								</div>

								<div>
									<span class="fw-600 title-heading">Amount : </span>
									<ShowPrice :object="expense_detail" object-key="amount" label="Amount"> </ShowPrice>
								</div>
								<div class="ml-1">
									<span class="fw-600 no-wrap">Remark : </span>
									<span>
										<ShowTextAreaValue :object="expense_detail" object-key="remark" label="remark">
										</ShowTextAreaValue>
									</span>
								</div>
								<div class="ml-1">
									<span class="fw-600 no-wrap">Expense Date : </span>
									<span v-if="expense_detail.expense_date">
										{{ formatDate(expense_detail.expense_date) }}
									</span>
									<em v-else class="text-muted fw-400">no expense date</em>
								</div>
							</v-col>
							<v-col md="6">
								<div v-if="expense_detail?.expense_type === 'company'">
									<span class="fw-600 title-heading">Budget : </span>
									<ShowValue :object="expense_detail" object-key="budget_name" label="budget name">
									</ShowValue>
								</div>
								<div v-if="expense_detail?.expense_type === 'company'">
									<span class="fw-600 title-heading">Budget cost : </span>
									<ShowPrice :object="expense_detail" object-key="budget_cost" label="budget cost">
									</ShowPrice>
								</div>
								<!-- <div v-if="expense_detail?.expense_type != 'company'">
										<span class="fw-600 title-heading">Amount : </span>
										<ShowPrice :object="expense_detail" object-key="amount" label="Amount"> </ShowPrice>
									</div> -->
								<template v-if="expense_detail.subbudget_name">
									<div>
										<span class="fw-600 title-heading">sub budget : </span>
										<ShowValue :object="expense_detail" object-key="subbudget_name" label="budget name">
										</ShowValue>
									</div>
									<div>
										<span class="fw-600 title-heading">sub budget cost : </span>
										<ShowPrice :object="expense_detail" object-key="subbudget_cost" label="budget cost">
										</ShowPrice>
									</div>
								</template>
							</v-col>
							<!-- <v-col md="6">
								
							</v-col> -->
							<v-col md="12" v-if="expense_detail.expense_type === 'company'">
								<p class="text-truncate mb-1 bold-600 text-uppercase py-2 border-bottom">Customer Detail</p>
								<v-layout class="ml-4 mt-3">
									<v-flex md4 v-if="expense_detail.customer">
										<div>
											<p
												class="text-truncate mb-1 bold-600 text-capitalize"
												v-if="expense_detail.customer && expense_detail.customer.display_name"
											>
												<span>{{ expense_detail.customer.display_name }}</span>
											</p>
										</div>
										<!-- {{ expense_detail.customer }} -->
										<p class="mb-1" v-if="expense_detail.customer.company_name">
											<v-icon left small>mdi-home-city-outline</v-icon>
											{{ expense_detail.customer.company_name }}
										</p>
										<p
											class="mb-1 text-lowercase"
											v-if="expense_detail.customer && expense_detail.customer.email"
										>
											<v-icon left small>mdi-email</v-icon>{{ expense_detail.customer.email }}
										</p>
										<p class="mb-1 text-lowercase" v-if="expense_detail.customer.phone">
											<v-icon left small>mdi-phone</v-icon>{{ expense_detail.customer.phone }}
										</p>
										<p class="mb-1" v-if="expense_detail.customer.designation">
											<v-icon left small>mdi-briefcase</v-icon> {{ expense_detail.customer.designation }}
										</p>
										<div class="d-flex" v-if="false && expense_detail.customer.address_line_1">
											<div>
												<v-icon left small>mdi-map</v-icon>
											</div>
											<div>
												<p class="mb-1" v-if="expense_detail.customer.unit_number">
													{{ expense_detail.customer.unit_number }}
												</p>
												<p class="mb-1" v-if="expense_detail.customer.address_line_1">
													{{ expense_detail.customer.address_line_1 }}
												</p>
												<p class="mb-1" v-if="expense_detail.customer.address_line_2">
													{{ expense_detail.customer.address_line_2 }}
												</p>
												<p class="mb-1" v-if="expense_detail.customer.address_country">
													{{ expense_detail.customer.address_country }}
													{{ expense_detail.customer.address_postal_code }}
												</p>
											</div>
										</div>
									</v-flex>
									<v-flex md4 v-if="expense_detail.customer?.default_address">
										<div>
											<p class="text-truncate mb-1 bold-600 text-capitalize">
												<span>Billing Address</span>
											</p>
										</div>
										<p
											class="mb-1 text-lowercase"
											v-if="expense_detail.customer?.default_address.display_name"
										>
											<v-icon left small>mdi-account</v-icon
											>{{ expense_detail.customer?.default_address.display_name }}
										</p>
										<p
											class="mb-1 text-lowercase"
											v-if="
												expense_detail.customer?.default_address &&
												expense_detail.customer?.default_address.email
											"
										>
											<v-icon left small>mdi-email</v-icon>
											{{ expense_detail.customer?.default_address.email }}
										</p>
										<p class="mb-1 text-lowercase" v-if="expense_detail.customer?.default_address.phone_no">
											<v-icon left small>mdi-phone</v-icon
											>{{ expense_detail.customer?.default_address.phone_no }}
										</p>
										<div class="mb-1 d-flex">
											<div>
												<v-icon left small>mdi-map</v-icon>
											</div>
											<div>
												<p class="mb-1" v-if="expense_detail.customer?.default_address.unit_number">
													{{ expense_detail.customer?.default_address.unit_number }}
												</p>
												<p class="mb-1" v-if="expense_detail.customer?.default_address.address_line_1">
													{{ expense_detail.customer?.default_address.address_line_1 }}
												</p>
												<p class="mb-1" v-if="expense_detail.customer?.default_address.address_line_2">
													{{ expense_detail.customer?.default_address.address_line_2 }}
												</p>
												<p class="mb-1" v-if="expense_detail.customer?.default_address.address_country">
													{{ expense_detail.customer?.default_address.address_country }}
													{{ expense_detail.customer?.default_address.address_postal_code }}
												</p>
											</div>
										</div>
									</v-flex>
									<v-flex md4 v-if="expense_detail.customer_contact_person">
										<div>
											<p class="text-truncate mb-1 bold-600 text-capitalize">
												<span>Contact Person</span>
											</p>
										</div>
										<p class="mb-1" v-if="expense_detail.customer_contact_person.display_name">
											<v-icon left small>mdi-account</v-icon>
											{{ expense_detail.customer_contact_person.display_name }}
										</p>
										<p
											class="mb-1 text-lowercase"
											v-if="
												expense_detail.customer_contact_person && expense_detail.customer_contact_person.email
											"
										>
											<v-icon left small>mdi-email</v-icon>{{ expense_detail.customer_contact_person.email }}
										</p>
										<p class="mb-1 text-lowercase" v-if="expense_detail.customer_contact_person.phone">
											<v-icon left small>mdi-phone</v-icon>{{ expense_detail.customer_contact_person.phone }}
										</p>
										<p class="mb-1" v-if="expense_detail.customer_contact_person.designation">
											<v-icon left small>mdi-briefcase</v-icon>
											{{ expense_detail.customer_contact_person.designation }}
										</p>
									</v-flex>
								</v-layout>
							</v-col>
							<v-col md="12" v-if="expense_detail.expense_type === 'company'">
								<p class="text-truncate mb-2 bold-600 text-uppercase py-2 border-bottom">Project Detail</p>
								<v-layout class="ml-4">
									<v-flex md12 v-if="expense_detail.project">
										<p class="mb-2" v-if="expense_detail.project">
											<b>Project cost : </b>
											<span class="fw-500">
												<ShowPrice
													:object="expense_detail.project"
													object-key="cost"
													label="project cost"
												></ShowPrice>
											</span>
										</p>
										<p
											class="mb-2"
											v-if="expense_detail.project && expense_detail.project.start_date_formatted"
										>
											<b>Start Date : </b>
											{{ expense_detail.project.start_date_formatted }}
										</p>
										<p
											class="mb-2"
											v-if="expense_detail.project && expense_detail.project.end_date_formatted"
										>
											<b>End Date : </b>
											{{ expense_detail.project.end_date_formatted }}
										</p>
										<p class="mb-2" v-if="expense_detail.project">
											<b>Description : </b>
											<ShowTextAreaValue
												:object="expense_detail.project"
												object-key="description"
												label="Description"
											></ShowTextAreaValue>
										</p>
										<table width="100%" class="detail-table scattered-table mt-2" v-if="false">
											<tr v-if="expense_detail.project && expense_detail.project.barcode">
												<th width="120px" valign="top" class="pa-1">Barcode</th>
												<td valign="top" class="pa-1">
													{{ expense_detail.project.barcode }}
												</td>
												<template v-if="expense_detail.project && expense_detail.project.start_date_formatted">
													<th width="80" valign="top" class="pa-1">Start Date</th>
													<td valign="top" class="pa-1">
														{{ expense_detail.project.start_date_formatted }}
													</td>
												</template>
												<template v-if="expense_detail.project && expense_detail.project.end_date_formatted">
													<th width="80" valign="top" class="pa-1">End Date</th>
													<td valign="top" class="pa-1">
														{{ expense_detail.project.end_date_formatted }}
													</td>
												</template>
											</tr>
											<tr v-if="expense_detail.project && expense_detail.project.name">
												<th width="120px" valign="top" class="pa-1">Name</th>
												<td valign="top" class="pa-1" colspan="5">
													{{ expense_detail.project.name }}
												</td>
											</tr>
											<tr>
												<th width="120px" valign="top" class="pa-1">Description</th>
												<td
													valign="top"
													class="pa-1"
													colspan="5"
													v-if="expense_detail.project && expense_detail.project.description"
												>
													<ShowTextAreaValue
														:object="expense_detail.project"
														object-key="description"
														label="Description"
													></ShowTextAreaValue>
												</td>
											</tr>
										</table>
									</v-flex>
								</v-layout>
							</v-col>
						</v-row>
					</div>
				</v-tab-item>
				<v-tab-item value="files">
					<div class="overflow-y" style="max-height: calc(100vh - 250px)">
						<v-row>
							<v-col md="12">
								<Files
									v-if="expenseTab == 'files'"
									class="mx-4"
									type-text="Expense Files"
									type="expense"
									:type-uuid="expense_detail?.uuid"
								>
								</Files>
							</v-col>
						</v-row>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</template>
		<template v-slot:action>
			<v-btn depressed tile v-on:click="$emit('close', false)"> Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import Files from "@/view/components/Files";
import Dialog from "@/view/components/Dialog";
import Loading from "@/view/components/Loading";
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import Chip from "@/view/components/Chip";
import { GetExpense } from "@/core/lib/expense.lib";
export default {
	name: "expense-create",
	title: "Create Expense",
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		uuid: {
			type: String,
			default: "",
		},
	},
	components: { Dialog, ShowValue, ShowTextAreaValue, Loading, ShowPrice, Chip, Files },
	data() {
		return {
			expenseTab: "detail",
			pageLoading: false,
			expansion_panel: null,
			expense_detail: {},
			actions: [
				{ title: "Approved", icon: "mdi-check-all", color: "green" },
				{ title: "Reject", icon: "mdi-cancel", color: "red lighten-1" },
				{ title: "Pending", icon: "mdi-progress-clock", color: "orange lighten-1" },
			],
		};
	},
	methods: {
		async getExpense() {
			const _this = this;
			_this.pageLoading = true;
			try {
				const data = await GetExpense(this.uuid);
				this.expense_detail = data;
				// console.log(data);
			} catch (error) {
				console.log(error);
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	mounted() {
		this.getExpense();
	},
};
</script>
<style scoped>
.title-heading {
	padding: 4px;
	/* min-width: 120px; */
	display: inline-block;
}
</style>
