<template>
	<v-sheet class="project" style="height: calc(100vh - 88px)">
		<v-row md="12" class="d-flex">
			<v-col md="4">
				<StatusCard
					type="expense"
					icons="mdi-account-group-outline"
					heading="Expense Total"
					:statusCount="tableSummary?.claim_expenses_total"
					statusColor="blue"
					textColor="blue--text"
				></StatusCard>
			</v-col>
			<v-col md="4">
				<StatusCard
					type="expense"
					icons="mdi-account-group-outline"
					heading="Company Expense"
					:statusCount="tableSummary?.company_expenses_total"
					statusColor="cyan darken-1"
					textColor="cyan--text darken-1"
				></StatusCard>
			</v-col>
			<v-col md="4">
				<StatusCard
					type="expense"
					icons="mdi-account-group-outline"
					heading="Individual Expense"
					:statusCount="tableSummary?.individual_expenses_total"
					statusColor="deep-orange darken-2"
					textColor="deep-orange--text darken-2"
				></StatusCard>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="4" class="py-0 my-auto">
				<div class="listing-heading">All Expenses</div>
				<v-select
					v-if="false"
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.project_status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="8" class="py-1 my-auto text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-btn
						v-if="getPermission('claim-expense::create')"
						depressed
						:disabled="pageLoading"
						tile
						color="blue darken-4"
						class="text-white"
						v-on:click="openCreateDialog()"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<v-btn
						v-if="false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>
					<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="advanceSearch = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn>
					<v-menu
						v-if="false"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-menu
						v-if="false"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-project"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('claim-expense')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-project-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('claim-expense')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('claim-expense')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('claim-expense')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
					<v-btn
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						v-on:click="exportDialogNew = true"
						tile
						depressed
					>
						<v-icon dark left>mdi-database-export</v-icon> Export
					</v-btn>
				</template>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Project #, Title, Customer Name and Project Name "
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			type="claim-expense"
			delete-endpoint="claim-expense/"
			:dialog-view="true"
			:dialog-update="true"
			v-on:reload:content="filterRows"
			v-on:view:dialog="openDetail($event)"
			v-on:update:dialog="openUpdateDialog($event)"
			delete-note="All transactions of this expense will also be deleted."
		></Table>
		<ExportDialogNew
			endpoint="export-expenses"
			title="Export Expense"
			:cols="draggableThead"
			:export-dialog.sync="exportDialogNew"
			v-on:close="exportDialogNew = false"
		></ExportDialogNew>
		<CreateExpense
			v-if="expenseCreateDialog"
			:uuid="uuid"
			:dialog="expenseCreateDialog"
			:update-dialog="expenseUpdateDialog"
			@close="closeExpenseCreateDialog"
			@success="getListing()"
		></CreateExpense>
		<ExpenseDetail
			v-if="expenseDetailDialog"
			:uuid="uuid"
			:dialog="expenseDetailDialog"
			@close="expenseDetailDialog = false"
			@success="getListing()"
		></ExpenseDetail>
		<confirmation-dialog
			:dialog="isRejectDialogOpen"
			title="Asset Stocks"
			:dialogWidth="400"
			current
			v-on:close="isRejectDialogOpen = false"
		>
			<template v-slot:title> Reject Perfroma </template>
			<template v-slot:body>
				<div>
					Expense with uuid
					<span class="red--text lighten-1 font-level-1-bold"># {{ uuid }}</span> will be reject forever
					and cannot be edit it later. Are you sure about reject it?
				</div>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="isRejectDialogOpen = false">
					Close
				</v-btn>
				<v-btn
					@click="(isRejectDialogOpen = false), (isExpenseDetailDialogOpen = false)"
					class="white--text"
					depressed
					color="orange"
					tile
				>
					Reject
				</v-btn>
			</template>
		</confirmation-dialog>
		<confirmation-dialog
			:dialog="isApproveDialogOpen"
			title="Asset Stocks"
			:dialogWidth="400"
			current
			v-on:close="isApproveDialogOpen = false"
		>
			<template v-slot:title> Approve Expense </template>
			<template v-slot:body>
				<div>
					Expense with uuid
					<span class="red--text lighten-1 font-level-1-bold"># {{ uuid }}</span> will be Approve Expense
					forever and cannot be edit it later. Are you sure about approve it?
				</div>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="isApproveDialogOpen = false">
					Close
				</v-btn>
				<v-btn
					@click="(isApproveDialogOpen = false), (isExpenseDetailDialogOpen = false)"
					class="white--text"
					depressed
					color="green"
					tile
				>
					Approve
				</v-btn>
			</template>
		</confirmation-dialog>
		<ExpenseFilter
			title="expense"
			:filter-dialog="advanceSearch"
			:btx-filter="listingFilter"
			:btx-query="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</ExpenseFilter>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import ConfirmationDialog from "@/view/components/ConfirmationDialog.vue";
import CreateExpense from "@/view/components/CreateExpense";
import ExpenseDetail from "@/view/components/ExpenseDetail";
import ExpenseFilter from "@/view/components/ExpenseFilter";
import StatusCard from "@/view/components/statusCard.vue";
export default {
	name: "expense-listing",
	title: "Listing Expense",
	mixins: [ListingMixin],
	components: {
		ConfirmationDialog,
		CreateExpense,
		ExpenseDetail,
		StatusCard,
		ExpenseFilter,
	},
	data() {
		return {
			uuid: null,
			pageTitle: "Expense",
			expenseCreateDialog: false,
			expenseUpdateDialog: false,
			expenseDetailDialog: false,
			pageBreadcrumbs: [{ text: "Expense Claim", disabled: true }],
			pageLoading: true,
			isRejectDialogOpen: false,
			isApproveDialogOpen: false,
			endpoint: "claim-expense",
			isExpenseDetailDialogOpen: false,
			defaultFilter: {},
		};
	},
	methods: {
		closeExpenseCreateDialog(val) {
			this.expenseCreateDialog = val;
		},
		openDetail(uuid) {
			this.uuid = uuid;
			this.expenseDetailDialog = true;
		},
		openCreateDialog() {
			this.uuid = null;
			this.expenseUpdateDialog = false;
			this.expenseCreateDialog = true;
		},
		openUpdateDialog(uuid) {
			this.uuid = uuid;
			this.expenseUpdateDialog = true;
			this.expenseCreateDialog = true;
		},
	},
};
</script>
